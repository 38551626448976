// Vendors
import BigPicture from "bigpicture";

// Helpers
import { Component } from "./helpers/component";
// import { Utils } from "./helpers/utils";
import { Lazyload } from "./helpers/lazyload";
// import MatchHeight from '@tannerhodges/match-height';

// Importing components
import { SideBar } from "../../components/sidebar/sidebar";
import { Share1 } from "../../components/share1/share1";

// Registering components
Component.register('SideBar', SideBar);
Component.register("Share1", Share1);

// Utils.credits();

// On DOM ready
document.addEventListener('DOMContentLoaded', () => {

  // Inits the helper module who lazy-load the images
  Lazyload.init();

  // Inits the components in page
  Component.initAll();

  // Inits the ObjectFit polyfill for IE11
  // if (window["objectFitImages"] !== undefined) {
  //   objectFitImages();
  // }

  $(document).on('click', `body:not(.zaux-search-active) .c-header__search-btn`, (e) => {
    if (!document.body.classList.contains('zaux-search-active')) {
      e.preventDefault();
      document.body.classList.add('zaux-search-active');
      document.querySelector('.c-header__search-field').focus();
    }
  });

  $(document).on('click', `.c-header__search`, (e) => {
    if (!document.body.classList.contains('zaux-search-active')) {
      e.preventDefault();
      document.body.classList.add('zaux-search-active');
      document.querySelector('.c-header__search-field').focus();
    }
  });

  $(document).on("click", (e) => {
    if ($(e.target).closest(`.c-header__search`).length === 0 || $(e.target).is(`.c-header__search`)) {
      if (document.body.classList.contains('zaux-search-active')) {
        document.body.classList.remove('zaux-search-active');
      }
    }
  });

  $(document).on('keyup', (e) => {
    if (e.key === "Escape") {
      if (document.body.classList.contains('zaux-search-active')) {
        e.preventDefault();
        document.body.classList.remove('zaux-search-active');
      }
    }
  });

  $(document).on("click", "[data-lightbox-gallery] a[href].lightbox:not(.lightbox--is-duplicate)", (e) => {
    e.preventDefault();
    let elGalleryWrapper = e.currentTarget.closest("[data-lightbox-gallery]");
    let galleryName = elGalleryWrapper.dataset.lightboxGallery;
    if (galleryName) {
      // Prevents duplicates in lightbox in case of swiper sliders
      Array.from(
        document.querySelectorAll(
          `[data-lightbox-gallery="${galleryName}"] .swiper-slide-duplicate a[href].lightbox`
        )
      ).forEach((e) => e.classList.add("lightbox--is-duplicate"));

      BigPicture({
        el: e.currentTarget,
        gallery: document.querySelectorAll(
          `[data-lightbox-gallery="${galleryName}"] a[href].lightbox:not(.lightbox--is-duplicate)`
        ),
        galleryAttribute: "href",
        loop: false,
      });
    }
  });

});
