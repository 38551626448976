import { Dom } from "../../assets/js/helpers/dom";

export class Share1 {

  static cssClass = "c-share1";
  static windowOpenTarget = "_blank";
  static windowOpenParams = "toolbar=0, status=0, width=626, height=436";

  constructor(el = null) {
    this.el = el;
  }

  static onBoot() {

    $(document).on('click', `.${Share1.cssClass}__btn-trigger`, (e) => {
      e.preventDefault();
      const $btn = $(Dom.getTarget(e.target, `.${Share1.cssClass}__btn-trigger`));
      $btn.next('[class*="__box"]').fadeToggle(300);
    });

    $(document).on('click', `.${Share1.cssClass}__btn--fb`, (e) => {
      e.preventDefault();

      const component = e.currentTarget.closest(`.${Share1.cssClass}`);
      // const shareTitle = component.dataset.title;
      const shareUrl = component.dataset.url;

      const queryString = new URLSearchParams({
        'm2w': "",
        's': 100,
        'p[url]': shareUrl
      }).toString();

      window.open('http://www.facebook.com/sharer/sharer.php?' + queryString, Share1.windowOpenTarget, Share1.windowOpenParams);

    });

    $(document).on('click', `.${Share1.cssClass}__btn--tw`, (e) => {
      e.preventDefault();

      const component = e.currentTarget.closest(`.${Share1.cssClass}`);
      const shareTitle = component.dataset.title;
      const shareUrl = component.dataset.url;

      const queryString = new URLSearchParams({
        'text': shareTitle,
        'url': shareUrl,
        'via': 'atiproject'
      }).toString();

      window.open('https://twitter.com/intent/tweet?' + queryString, Share1.windowOpenTarget, Share1.windowOpenParams);
    });

    $(document).on('click', `.${Share1.cssClass}__btn--in`, (e) => {
      e.preventDefault();

      const component = e.currentTarget.closest(`.${Share1.cssClass}`);
      const shareTitle = component.dataset.title;
      const shareUrl = component.dataset.url;

      const queryString = new URLSearchParams({
        'mini': 'true',
        'title': shareTitle,
        'url': shareUrl,
      }).toString();

      window.open('https://www.linkedin.com/shareArticle?' + queryString, Share1.windowOpenTarget, Share1.windowOpenParams);
    });

  }

}
