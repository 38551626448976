
import { Dom } from "../../assets/js/helpers/dom";
import { Utils } from "../../assets/js/helpers/utils";

export class SideBar {
  static cssClass = "c-sidebar";
  static mainPanelId = 1;

  constructor(el = null) {
    this.$el = $(el);
    this.$backdrop = $(`.${SideBar.cssClass}__backdrop:first`);
    this.stack = [];
    this.setupEvents();
  }

  setupEvents() {

    this.$el.on('mouseenter mouseleave', (e) => {
      if (e.type === 'mouseenter') {
        this.toggleMainPanel(true);
      } else {
        this.toggleMainPanel(false);
      }
    });

    $(document).on('click', `.${SideBar.cssClass}__btn-toggle`, (e) => {
      e.preventDefault();
      const _this = $(`.${SideBar.cssClass}:first`)[0].instance;
      _this.toggleMainPanel();
    });

    $(document).on('click', `.${SideBar.cssClass}__btn-close`, (e) => {
      e.preventDefault();
      const _this = $(`.${SideBar.cssClass}:first`)[0].instance;
      _this.toggleMainPanel(false);
    });

    $(document).on('click', `.${SideBar.cssClass}__btn-prev`, (e) => {
      e.preventDefault();
      const _this = $(`.${SideBar.cssClass}:first`)[0].instance;

      if (_this.stack.length > 1) {
        const $panel = $(e.target).closest(`.${SideBar.cssClass}__panel`);
        _this.close(_this.getPanelId($panel[0]));
      } else {
        _this.toggleMainPanel(false);
      }
    });

    $(document).on('click', '[data-sidebar-open-panel]', (e) => {
      e.preventDefault();
      const $btn = $(Dom.getTarget(e.target, '[data-sidebar-open-panel]'));
      const panelTargetId = $btn.data('sidebar-open-panel');

      if (panelTargetId) {
        $(`[data-sidebar-open-panel="${panelTargetId}"]`).addClass('active');
        this.open(panelTargetId);
      }
    });

  }

  isPanelOpen(panelId) {
    return this.stack.indexOf(parseInt(panelId, 10)) !== -1;
  }

  toggleMainPanel(doOpen) {
    const _this = $(`.${SideBar.cssClass}:first`)[0].instance;
    const $btnsToggle = $(`.${SideBar.cssClass}__btn-toggle`);
    const cssClassActive = `${Utils.getVarNS()}-sidebar-active`;
    const bodyClassList = document.body.classList;

    if (doOpen === undefined) {
      doOpen = !bodyClassList.contains(cssClassActive);
    }

    if (doOpen && !_this.isPanelOpen(SideBar.mainPanelId)) {
      $btnsToggle.addClass('is-active');
      bodyClassList.add(cssClassActive);

      //console.log(`adding ${1} lv ${SideBar.mainPanelId}`);
      _this.stack.push(SideBar.mainPanelId);

      //console.log(_this.stack);
    } else if (!doOpen && _this.isPanelOpen(SideBar.mainPanelId)) {
      $btnsToggle.removeClass('is-active');
      bodyClassList.remove(cssClassActive);
      _this.closeSubpanelsOfLevel();
      _this.handleBackdrop();
      _this.stack.pop();
      //console.log(_this.stack);
    }
  }

  getPanelId(elPanel) {
    if (elPanel) {
      return elPanel.getAttribute('id').split('-').slice(-1)[0];
    }
    return false;
  }

  getPanelLevel(elPanel) {
    if (elPanel) {
      return parseInt(elPanel.dataset.level, 10);
    }
    return false;
  }

  handleBackdrop() {
    this.$backdrop.stop(true)[this.stack.length > 1 ? 'fadeIn' : 'fadeOut'](300);
  }

  open(panelId) {
    const $panel = $(`#sidebar-panel-${panelId}`);

    if ($panel.length > 0) {
      const panelLevel = this.getPanelLevel($panel[0]);

      if (!this.isPanelOpen(panelId)) {

        this.closeSubpanelsOfLevel(panelLevel - 1);

        //console.log(`adding ${panelId} lv ${panelLevel}`);
        this.stack.push(panelId);

        // Open routine
        $panel.one('transitionend', () => {
          $panel.removeClass('opening');
          $panel.addClass('open');
          //console.log(`opened ${panelId} lv ${panelLevel}`);
          //console.log(this.stack);
        });
        $panel.removeClass('closing');
        $panel.addClass('opening');

      } else {

        this.closeSubpanelsOfLevel(panelLevel);

      }

      // Resets Y scroll position
      $panel.find(`.${SideBar.cssClass}__panel-content`)[0].scrollTo(0, 0);

      this.handleBackdrop();
    }
  }

  close(panelId) {
    const $panel = $(`#sidebar-panel-${panelId}`);

    if (this.isPanelOpen(panelId)) {

      this.stack.pop();

      $panel.removeClass('open');

      // Removes active state from trigger items
      $(`[data-sidebar-open-panel="${panelId}"].active`).removeClass('active');

      // Close routine
      $panel.one('transitionend', () => {
        $panel.removeClass('open opening closing');
        //console.log(`closed ${panelId} lv ${this.getPanelLevel($panel[0])}`);
        //console.log(this.stack);
      });
      $panel.addClass('closing');

      this.handleBackdrop();
    }
  }

  closeSubpanelsOfLevel(parentLevel = 1) {
    $(`.${SideBar.cssClass}__panel`).filter((i, el) => {
      return parseInt(el.dataset.level, 10) > parentLevel && (el.classList.contains('opening') || el.classList.contains('open'));
    }).sort((a, b) => +b.dataset.level - +a.dataset.level).each((i, el) => {
      this.close(this.getPanelId(el));
    });
  }

}
